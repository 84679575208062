import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';

const Button = styled.button`
  margin: 0 0 20px 0;
  padding: 5px 15px;
  background-color: ${props => props.type === "primary" ? variables.brand_cta: 'transparent'};
  color: ${props => props.type === "primary" ? variables.brand_primary: variables.brand_cta};
  border-width: 3px;
  border-color: ${props => props.type === "primary" ? variables.brand_cta : 'rgba(22,29,111,0.1)' };
  text-transform: uppercase;
  border-style: solid;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: ${props => props.inverted === "true" ? variables.white : variables.brand_primary};
    color: ${variables.brand_cta};
    border-color: ${props => props.type === "primary" ? variables.brand_cta : 'rgba(22,29,111,1)' };
  }

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    margin: 0 20px 20px 0;
  } 
`

const Btn = ({type, text, href, inverted, linkToExternal, id}, props) => (
    <Button type={type} inverted={inverted} id={id}>
      {(linkToExternal === true) ?
        <a href={href} target="_blank" rel="noreferrer">{text}</a>
      : 
        <Link to={href}>{text}</Link>
      }
    </Button>
)

Btn.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
}

Btn.defaultProps = {
    type: "secondary",
    text: "Mehr Informationen",
    href: "/",
}

export default Btn