// Globally used style variables

// Colors

export const black = '#000000';
export const white = '#f6f6f6';
export const brand_cta = '#161d6f';
export const brand_primary = '#c7ffd8';
export const brand_secondary = '#98ded9';

// Fonts
export const text_font = "'Open Sans', 'sans-serif'";
export const brand_font = "'Josefin Sans', 'sans-serif'";

// FONT SIZES
// Mobile
export const font_size__mobile_big = '2.1875em';       // 35px
export const font_size__mobile_medium = '1.5625em';    // 25px
export const font_size__mobile_small = '1.25em';       // 20px
export const font_size__mobile_text = '1em';           // 16px

// Tablet
export const font_size__tablet_big = '2.8125em';       // 45px
export const font_size__tablet_medium = '2.1875em';    // 35px
export const font_size__tablet_small = '1.25em';       // 20px
export const font_size__tablet_text = '1.125em';       // 18px

// Desktop
export const font_size__desktop_big = '4em';           // 65px
export const font_size__desktop_medium = '2.1875em';   // 35px
export const font_size__desktop_small = '1.5625em';    // 25px
export const font_size__desktop_text = '1.125em';      // 18px


// Font weight
export const font_regular = 400;
export const font_semibold = 600;
export const font_bold = 700;
