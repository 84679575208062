import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import * as variables from '../utilities/style-variables'

const Container = styled.div`
  background-color: ${props => props.inverted === "true" ? variables.brand_primary : variables.white};
`

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  margin: 0px;
`

const Li = styled.li`
  &:first-child{
    padding-right: 10px;
  }

  &:not(:first-child) {
    border-left: 2px solid ${variables.black};
    padding: 0 10px;
  }

  &:last-child{
    font-weight: ${variables.font_regular};
    font-style: italic;
  }

  &::after {
        content: "";
        display: inline-block;
        width: 0px;
        background-color: ${props => props.inverted === "true" ? variables.white : variables.brand_primary};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: all ease-in-out 300ms;
    }

    &:hover {
        &::after {
            width: 100%;
            transition: all ease-in-out 300ms;
        }
    }
`


const Breadcrumb = ({breadcrumb, inverted}) => {

  const breadcrumbContent = breadcrumb

  return (
    <>
      <Container inverted={inverted}>
        <div className="container">
          <Ul>
            <Li>
              <Link to="/">Home</Link>
            </Li>
            {Object.entries(breadcrumbContent).map(([text, url], index) =>{
              return (
                <Li key={index}>
                  <Link to={url}>{text}</Link>
                </Li>
              )
            })}
          </Ul>
        </div>
      </Container>
    </>
  )
}

export default Breadcrumb