import React from 'react'
import PropTypes from "prop-types"

import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';
import topSpikes from "../images/bg-spikes-top.svg"
import btmSpikes from "../images/bg-spikes-bottom.svg"

const TopSpikes = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
  background-image: url(${topSpikes});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
`

const BtmSpikes = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
  background-image: url(${btmSpikes});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: top;
`

const ContentSection = styled.section`
  position: relative;
  margin: ${props => props.inverted === "true" ? '0' : (props.spikes ? '0' : '50px 0')};
  padding: ${props => props.inverted === "true" ? '50px 0' : (props.spikes ? '50px 0' : '0')};
  background-image: ${props => props.bgGradient ? 'linear-gradient(#c7ffd8, #98ded9)' : 'none'};
  overflow: hidden;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    margin: ${props => props.inverted === "true" ? '0' : props.spikes ? '0' : props.last ? '80px 0 0' : '80px 0 80px'};
    padding: ${props => props.inverted === "true" ? '80px 0 80px' : '0'};

    &::after {
      content:'';
      position: absolute;
      display: ${props => props.cutOff ? 'block' : 'none'};
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100px;
      border-left: 4000px solid transparent;
      border-bottom: 100px solid ${variables.white};
    }
  } 
`

const Separator = styled.div `
  display: ${props => props.noSeparator ? 'none' : 'block'};
  position: relative;
  width: 150px;
  height: 20px;
  margin: 0 auto 30px;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    width: 250px;
    height: 35px;
    margin-bottom: 80px;
  } 
`

const SeparatorLine = styled.span `
  display: block;
  position: absolute;
  width: 100px;
  height: 5px;
  margin-bottom: 5px;
  transform: skew(-20deg);
  background-color: ${variables.black};
  
  &.sl-1 {
    left: 0;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
      left: -10px;
    } 
  }
  &.sl-2 {
    right: 0;
    bottom: 0;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
      right: -10px;
    } 
  }

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    width: 200px;
    height: 10px;
  } 
`

const Section = ({ bgGradient, children, spikes, noSeparator, inverted, cutOff, last, id}) => {
  return (
    <>
      {spikes && <TopSpikes></TopSpikes>}
      <ContentSection last={last} bgGradient={bgGradient} spikes={spikes} inverted={inverted} cutOff={cutOff} id={id}>
        <div className="container">
          <Separator noSeparator={noSeparator}>
            <SeparatorLine className="sl-1"></SeparatorLine>
            <SeparatorLine className="sl-2"></SeparatorLine>
          </Separator>
          {children}
        </div>
      </ContentSection>
      {spikes && <BtmSpikes></BtmSpikes>}
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Section
