import React, { useEffect, useState} from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import * as variables from '../utilities/style-variables'
import Btn from './button'

const Nav = styled.nav `
    background-color: ${props => props.inverted === "true" ? variables.brand_primary : "transparent"};
    height: 80px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease-in-out;
    }
`

const NavbarContainer = styled.div `
    display: flex;
    justify-content: center;
    height: 80px;
    z-index: 1;
    width: 100%;

    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        justify-content: flex-start;
        align-items: baseline;
    }
`

const NavLogo = styled(props => <Link {...props} />) `
    color: ${variables.black};
    justify-self: flex-start;
    align-self: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 1em;
    display: flex;
    align-items: center;
    font-weight: 600;
    z-index: 9999;

    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        margin-right: 50px;
    }
`

const MobileIcon = styled.div `
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    cursor: pointer;

    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        display: none;
    }
`

const HamburgerMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: center;
    width: 20px;
    height: 20px;

    &.menu-close {
        justify-content: center;
    }
`

const Line = styled.span`
    height: 2px;
    background-color: ${variables.black};

    &.line-1{
        transform: rotateZ(45deg);
    }
    &.line-2{
        margin-top: -2px;
        transform: rotateZ(-45deg);
    }
`

const NavMenu = styled.ul `
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  top: ${({click}) => (click ? '100%' : '-4000px')};
  color: inherit;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  background-color: ${props => props.inverted === "true" ? variables.brand_primary : variables.white};

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    top: 0;
    height: auto;
    list-style: none;
    text-align: center;
    background-color: ${props => props.inverted === "true" ? variables.brand_primary : "transparent"};
  }
`

const NavItem = styled.li `
    width: 100%;
    font-weight: ${variables.font_semibold};

    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        height: 80px;
        width: auto;
    }
`

const NavItemContact = styled.li `
    width: 100%;
    display: none;

    @media (min-width: 1200px) { // X-Large devices (large desktops, 1200px and up)
        height: 80px;
        width: auto;
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        align-items: flex-end;
    }
`

const NavLink = styled(props => <Link {...props} />) `
    display: block;
    padding: 30px;
    width: 100%;
    text-align: center;

    &::before {
            content: '';
            width: 0px;
        }

    &:hover {

        &::before {
            display: block;
            position: absolute;
            background-color: ${props => props.inverted ? variables.black : '#98ded9'};
            width: 50px;
            height: 3px;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: 0 auto;
            transition: all 0.3s ease-in-out;
        }
    }

    &.active {
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: ${props => props.inverted ? variables.black : '#98ded9'};
            width: 50px;
            height: 3px;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: 0 auto;
        }
    }

    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        height: 100%;
        text-decoration: none;
    }
`

const Navigation = ({inverted, noContact}) => {
    const [click, setClick] = useState(false)
    const [scroll, setScroll] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const changeNav = () => {
        if (window.scrolly >= 80) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        changeNav()
        window.addEventListener('scroll', changeNav)
    }, [])

    return (
        <>
            <Nav inverted={inverted} active={scroll} click={click}>
                <NavbarContainer className="container">
                    <NavLogo to="/" onClick={closeMobileMenu}>
                        <StaticImage 
                            src="../images/cliqit-logo.png" 
                            alt="cliqit" 
                            formats={["auto","webp"]}
                            height={40}
                            placeholder="blurred"
                            layout="constrained"
                        />
                    </NavLogo>
                    <MobileIcon onClick={handleClick}>
                        {click ? 
                            <HamburgerMenu className="menu-close">
                                <Line className="line-1"></Line>
                                <Line className="line-2"></Line>
                            </HamburgerMenu> 
                        : 
                            <HamburgerMenu>
                                <Line></Line>
                                <Line></Line>
                                <Line></Line>
                            </HamburgerMenu> 
                        }
                    </MobileIcon>
                    <NavMenu onClick={handleClick} click={click} inverted={inverted}>
                        <NavItem>
                            <NavLink to="/" activeClassName="active" inverted={inverted}>Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/online-marketing" activeClassName="active" inverted={inverted}>Online Marketing</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/website-und-seo" activeClassName="active" inverted={inverted}>Website & SEO</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/webtracking" activeClassName="active" inverted={inverted}>Web-Tracking</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/ueber-cliqit" activeClassName="active" inverted={inverted}>Über cliqit</NavLink>
                        </NavItem>
                        {noContact ? null :
                        <NavItemContact>
                          <Btn
                            inverted={inverted}
                            type="secondary"
                            text="Kontakt aufnehmen"
                            href="#contact-form"
                          />
                        </NavItemContact>
                        }
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navigation