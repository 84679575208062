import * as React from "react"
import Navigation from './navigation'

const Header = ({inverted, noContact}) => (
  <header>
    <Navigation inverted={inverted} noContact={noContact} />
  </header>
)

export default Header
